/* src/pages/ReportPage.css */

.report-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    max-width: 1000px;
    margin: 0 auto;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    table-layout: fixed;
  }
  
  th,
  td {
    border: 1px solid #dddddd;
    padding: 12px;
    text-align: left;
    word-wrap: break-word;
  }
  
  th {
    background-color: #0066cc;
    color: white;
  }
  
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  tr:hover {
    background-color: #e0e0e0;
  }
  
  /* Responsive adjustments */
  @media screen and (max-width: 768px) {
    table,
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
      width: 100%;
    }
  
    th,
    td {
      padding: 10px;
      font-size: 12px;
      box-sizing: border-box;
    }
  
    tr {
      margin-bottom: 15px;
      display: block;
      border-bottom: 2px solid #dddddd;
    }
  
    td {
      display: flex;
      justify-content: space-between;
      text-align: right;
      border-bottom: 1px solid #eeeeee;
      position: relative;
      padding-left: 50%;
    }
  
    td:before {
      content: attr(data-label);
      position: absolute;
      left: 0;
      width: 50%;
      padding-left: 15px;
      font-weight: bold;
      text-align: left;
    }
  }
  
  @media screen and (max-width: 480px) {
    th,
    td {
      font-size: 10px;
      padding: 8px;
    }
  }
  