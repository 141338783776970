/* src/pages/Login.css */

.login-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  background-color: #f0f4f8;
}

.login-logo {
  max-width: 200px;
  height: auto;
  margin-bottom: 20px;
}

.login-page h2 {
  margin-bottom: 20px;
  font-size: 1.5rem;
  color: #004080;
}

.login-page button {
  padding: 10px 20px;
  font-size: 1rem;
  color: white;
  background-color: #0078d4;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.login-page button:hover {
  background-color: #005a9e;
}
