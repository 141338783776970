/* src/components/Footer.css */

.App-footer {
    color: #011735;
    text-align: center;
    padding: 10px;
    position: relative;
    bottom: 0;
    width: 100%;
  }
  
  .App-footer p {
    margin: 0;
    font-size: 0.9rem;
  }
  
  /* Responsive adjustments */
  @media screen and (max-width: 768px) {
    .App-footer p {
      font-size: 0.8rem;
    }
  }
  
  @media screen and (max-width: 480px) {
    .App-footer p {
      font-size: 0.7rem;
    }
  }
  