/* src/App.css */

.App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  main {
    flex: 1;
    padding: 20px;
    box-sizing: border-box;
  }
