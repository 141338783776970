/* src/components/Header.css */

.App-header {
    padding: 20px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .App-logo {
    max-width: 150px;
    height: auto;
  }
  
  .App-header h1 {
    flex: 1;
    font-size: 1.5rem;
    margin: 0;
    text-align: center;
  }
  
  .App-header nav {
    margin-left: auto;
  }
  
  .App-header nav a {
    color: white;
    text-decoration: none;
    margin: 0 15px;
  }
  
  .App-header nav a:hover {
    text-decoration: underline;
  }
  
  /* Responsive adjustments */
  @media screen and (max-width: 768px) {
    .App-header {
      flex-direction: column;
      align-items: center;
    }
  
    .App-logo {
      max-width: 120px;
      margin-bottom: 10px;
    }
  
    .App-header h1 {
      font-size: 1.2rem;
    }
  
    .App-header nav {
      margin-top: 10px;
    }
  }
  
  @media screen and (max-width: 480px) {
    .App-header h1 {
      font-size: 1rem;
    }
  
    .App-logo {
      max-width: 100px;
    }
  }
  