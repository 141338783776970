/* src/components/ReportSelection.css */

#report-selection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  background-color: #f0f4f8;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 0 auto;
}

.company-id-input {
  margin-bottom: 20px;
  width: 100%;
}

.company-id-input label {
  font-weight: bold;
  color: #004080;
  margin-bottom: 10px;
  display: block;
}

.company-id-input input {
  padding: 10px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  font-size: 1em;
  width: 80%;
  max-width: 400px;
  margin-bottom: 20px;
}

.report-buttons {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  align-items: center;
}

.report-buttons button {
  background-color: #0066cc;
  color: white;
  padding: 15px 25px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2em;
  width: 100%;
  max-width: 400px;
  transition: background-color 0.3s ease;
}

.report-buttons button:hover {
  background-color: #004080;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
  .company-id-input input {
    width: 100%;
  }

  .report-buttons button {
    max-width: 300px;
  }
}

@media screen and (max-width: 480px) {
  .company-id-input label {
    font-size: 0.9em;
  }

  .report-buttons button {
    font-size: 1em;
    padding: 10px 20px;
    max-width: 250px;
  }
}
